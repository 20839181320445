<template>
  <div class="mei-zhongqina">
    <div class="f-13 rules" @click="toPage('rules')">活动规则</div>
    <div class="text-center m-t-10">
      <span class="mei white">未中签</span>
    </div>
    <div class="group">
      <div class="text-center f-17">感谢您对轻松小镇的支持与贡献</div>
      <div class="code text-center">
        <img :src="require('../assets/img/fail-code.png')"/>
      </div>
      <div class="text-center f-12">
        后续我们还将推出其他数字藏品与更多玩法<br>
        欢迎扫描上面的二维码添加社区成员<br>
        第一时间获得最新资讯
      </div>
    </div>
    <van-overlay :show="isShow" @click="close" z-index="10">
      <div class="wrapper">
        <div class="block">
          <div class="close">
            <van-icon name="cross" size="15" color="#69686D" @click="close"/>
          </div>
          <div class="f-23 bold m-b-10">很抱歉，你没抢到</div>
          <div class="f-17 m-b-40">「姜小竹数字艾灸盒」0元资格</div>
          <div class="f-20 m-b-40">
            感谢您的参与<br>赠送您99元石
          </div>
          <div class="btn f-17 m-b-10" @click="close">收下元石</div>
          <div class="grey f-11">元石后续将解锁更多玩法，敬请期待</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'Fail',
  data () {
    return {
      isShow: false
    }
  },
  created () {
    // if (!localStorage.getItem('hasFailed')) {
    //   this.isShow = true
    // }
  },
  methods: {
    toPage (name) {
      this.$router.push({ name })
    },
    close () {
      this.isShow = false
      localStorage.setItem('hasFailed', true)
    }
  }
}
</script>

<style scoped lang="scss">
.mei-zhongqina{
    min-height: 100vh;
    background: url("../assets/img/rrrr-bg.jpg") no-repeat;
    background-size: 100% auto;
    position: relative;
    color: black;
    padding: 380px 0 30px;
    .rules{
      position: absolute;
      right: 0;
      top: 30px;
      background-image: linear-gradient(to bottom,rgba(165, 208, 254, 0.99), rgba(226, 208, 216, 0.99));
      border: 1px solid rgba(255,255,255,.1);
      box-shadow: 3px 8px 19px 1px rgba(43, 96, 128, 0.42);
      opacity: 0.8;
      border-radius: 3px 0 0 3px;
      width: 23px;
      padding: 10px 5px;
      color: white;
    }
    .mei{
      font-size: 37px;
      line-height: 72px;
    }
    .group{
      background: url("../assets/img/fail.png") no-repeat;
      background-size: 100% auto;
      padding: 70px 50px 80px;
      .code{
        margin: 25px 0;
        >img{
          width: 133px;
          height: 133px;
        }
      }
    }
    .wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        width: 85%;
        background-color: #fff;
        border-radius: 10px;
        position: relative;
        padding: 45px 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .close {
          position: absolute;
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background: #ECECEC;
          right: 10px;
          top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btn{
          background: #000000;
          border-radius: 25px;
          color: white;
          padding: 10px 57px;
          display: inline-block;
        }
      }
    }
  }
</style>
