<template>
  <div class="zhongqian">
    <div class="text-center">
      <img :src="require('../assets/img/zhongqian.png')" width="50%"/>
    </div>
    <div class="f-20 white text-center des">轻松小镇</div>
    <div class="status">
      <div class="bold m-b-15 text-center f-16">我的中签码：{{code}}</div>
      <div class="text-center f-12">恭喜获得【姜小竹数字艾灸盒】0元抢购资格</div>
    </div>
    <div class="buy">
      <div class="buy-wrap">
        <div class="video">
          <video :poster="require('../assets/img/video-poster.jpg')"
                 :src="require(Qimg +'reserve-video.mp4')"
                 loop autoplay playsinline controls preload="auto"
                 width="100%" height="100%"></video>
          <div class="count-down text-center white f-12" v-if="status === 1">
            距抢购时间开启：{{startTime}}
          </div>
          <div class="count-down text-center white f-12" v-if="status === 2">
            距抢购时间结束：{{endTime}}
          </div>
          <div class="count-down text-center white f-12" v-if="status === 3">
            抢购已结束
          </div>
        </div>
        <div class="detail">
          <div class="bold f-23 m-b-20 text-center">姜小竹珍藏款数字艾灸盒</div>
          <div class="f-17 m-b-25 text-center">限量  299份</div>
          <div class="black-btn text-center bg-gray" v-if="status === 1">即将开抢</div>
          <van-button block class="black-btn text-center"
                      :loading="isLoading"
                      v-else-if="status === 2" @click="buy">
            <span class="f-20">0元抢购</span>
          </van-button>
          <div class="black-btn text-center" v-else>已售罄</div>
        </div>
      </div>
    </div>
    <van-overlay :show="isShow" @click="isShow = false" z-index="10">
      <div class="wrapper">
        <div class="block success" v-if="isGet">
          <div class="close">
            <van-icon name="cross" size="15" color="#69686D" @click="isShow = false"/>
          </div>
          <div class="up"></div>
          <div class="down">
            <div class="f-23 bold">抢购成功</div>
            <div class="btn" @click="toPage('detail')">前往查看</div>
            <div class="grey f-11">可于「个人中心 - 轻松藏品 - 数字按摩器」中查看详情</div>
          </div>
        </div>
        <div class="block fail" v-else>
          <div class="close">
            <van-icon name="cross" size="15" color="#69686D" @click="isShow = false"/>
          </div>
          <div class="up">
            <div class="white bold f-23 m-b-20">抢购失败</div>
            <div class="white f-17">抱歉该数字产品已被抢完</div>
          </div>
          <div class="down">
            <div class="f-17">感谢您的参与，赠送您{{number}}元石</div>
            <div class="btn" @click="isShow = false">收下元石</div>
            <div class="grey f-11">元石后续将解锁更多玩法，敬请期待</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="isShowAttest" @click="isShowAttest = false">
      <div class="wrapper" @click.stop>
        <div class="attest">
          <div class="f-18 m-b-25">确定实名认证吗？</div>
          <div class="f-15 m-b-25">实名认证后不可修改，请确认填写的 信息无误</div>
          <div class="btn-group">
            <div class="cancel" @click="isShowAttest = false">取消</div>
            <div class="confirm" @click="toPage('attest')">前往认证</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { nowBuyApi } from '../api/user'
import { mapState } from 'vuex'
export default {
  name: 'Status2',
  props: ['code'],
  data () {
    return {
      startTime: '',
      endTime: '',
      status: 1,
      start: localStorage.getItem('time4'),
      end: localStorage.getItem('time5'),
      timer: null,
      isShow: false,
      isGet: true,
      isShowAttest: false,
      number: 0,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created () {
    this.getStatus()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    async buy () {
      if (this.user.isAttested) {
        this.isLoading = true
        try {
          await nowBuyApi({})
          this.isLoading = false
          this.isGet = true
          this.isShow = true
        } catch (e) {
          this.isLoading = false
          console.log(e)
          if (e.code === -5) {
            this.isShowAttest = true
          } else if (e.code === -10) {
            this.isGet = false
            this.number = e.info
            this.isShow = true
          } else {
            this.$toast.fail(e.data)
          }
        }
      } else {
        this.isShowAttest = true
      }
    },
    getStatus () {
      const now = new Date().getTime()
      if (now < this.start) {
        this.status = 1
        this.timer = setInterval(() => {
          const time = this.countTime(this.start)
          if (!time) {
            this.showBuy()
          } else {
            this.startTime = time
          }
        }, 1000)
      } else if (now >= this.start && now <= this.end) {
        this.showBuy()
      } else {
        this.status = 3
      }
    },
    showBuy () {
      this.timer = setInterval(() => {
        const time = this.countTime(this.end)
        if (!time) {
          this.status = 3
          clearInterval(this.timer)
        } else {
          this.endTime = time
        }
      }, 1000)
      this.status = 2
    },
    countTime (endTime) {
      const now = new Date().getTime()
      const duration = endTime - now
      if (duration <= 0) {
        return false
      }
      const day = Math.floor(duration / (1000 * 60 * 60 * 24))
      const hour = Math.floor(duration / (1000 * 60 * 60) % 24)
      const min = Math.floor(duration / (1000 * 60) % 60)
      const s = Math.floor(duration / 1000 % 60)
      return `${day ? day + '天' : ''}${hour}时${min}分${s}秒`
    },
    toPage (name) {
      this.$router.push({ name })
    }
  }
}
</script>

<style scoped lang="scss">
.zhongqian{
  padding: 380px 0 0;
  .rules{
    position: absolute;
    right: 0;
    top: 30px;
    background-image: linear-gradient(to bottom,rgba(165, 208, 254, 0.99), rgba(226, 208, 216, 0.99));
    border: 1px solid rgba(255,255,255,.1);
    box-shadow: 3px 8px 19px 1px rgba(43, 96, 128, 0.42);
    opacity: 0.8;
    border-radius: 3px 0 0 3px;
    width: 23px;
    padding: 10px 5px;
    color: white;
  }
  .des{
    margin-top: -35px;
    letter-spacing: 3px;
    margin-bottom: 30px;
  }
  .status{
    margin: 22px 17px 25px;
    border-radius: 10px;
    padding: 25px 14px;
    background-image: linear-gradient(to top, rgba(255,255,255,.8), rgba(255,255,255,.3));
    border: 1px solid rgba(255,255,255,.5);
    overflow: hidden;
    :after{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(2px);
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
  .buy{
    margin: 22px 17px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #FFFFFF;
    box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
    border-radius: 10px;
    padding: 12px;
    .buy-wrap{
      border-radius: 3px 3px 0px 0px;
      overflow: hidden;
      .video{
        height: 317px;
        position: relative;
        .count-down{
          top: 11px;
          position: absolute;
          padding: 10px;
          left: 8%;
          right: 8%;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 7px;
        }
      }
      .detail{
        padding: 25px 30px 30px;
        .black-btn{
          font-size: 20px;
          font-weight: 500;
          color: #FFFFFF;
          background: #000000;
          border-radius: 20px;
          height: 41px;
          line-height: 41px;
        }
        .bg-gray{
          background: #AEAEAE;
        }
      }
    }
  }
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 85%;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      .close {
        position: absolute;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #ECECEC;
        right: 10px;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .5;
      }
      .down{
        padding: 40px 20px 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .btn{
          background: #000000;
          border-radius: 25px;
          color: white;
          padding: 10px 57px;
          display: inline-block;
          margin-bottom: 15px;
          margin-top: 44px;
        }
      }
    }
    .success{
      .up{
        height: 138px;
        background: url("../assets/img/win-success.jpg") no-repeat;
        background-size: 100% 100%;
      }
    }
    .fail{
      .up{
        height: 138px;
        background: url("../assets/img/win-fail.jpg") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
    }
    .attest{
      width: 80%;
      padding: 35px 20px 20px;
      background: #FFFFFF;
      border-radius: 10px;
      .btn-group{
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        .cancel{
          width: 104px;
          height: 38px;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          text-align: center;
        }
        .confirm{
          width: 104px;
          height: 38px;
          background: #000000;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          color: white;
          text-align: center;
        }
      }
    }
  }
}
</style>
